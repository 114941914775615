export default {
    d00:require('./statics/images/dayweather/d00.png'),
    d01:require('./statics/images/dayweather/d01.png'),
    d02:require('./statics/images/dayweather/d02.png'),
    d03:require('./statics/images/dayweather/d03.png'),
    d04:require('./statics/images/dayweather/d04.png'),
    d05:require('./statics/images/dayweather/d05.png'),
    d06:require('./statics/images/dayweather/d06.png'),
    d07:require('./statics/images/dayweather/d07.png'),
    d08:require('./statics/images/dayweather/d08.png'),
    d09:require('./statics/images/dayweather/d09.png'),
    d10:require('./statics/images/dayweather/d10.png'),
    d11:require('./statics/images/dayweather/d11.png'),
    d12:require('./statics/images/dayweather/d12.png'),
    d13:require('./statics/images/dayweather/d13.png'),
    d14:require('./statics/images/dayweather/d14.png'),
    d15:require('./statics/images/dayweather/d15.png'),
    d16:require('./statics/images/dayweather/d16.png'),
    d17:require('./statics/images/dayweather/d17.png'),
    d18:require('./statics/images/dayweather/d18.png'),
    d19:require('./statics/images/dayweather/d19.png'),
    d20:require('./statics/images/dayweather/d20.png'),
    d21:require('./statics/images/dayweather/d21.png'),
    d22:require('./statics/images/dayweather/d22.png'),
    d23:require('./statics/images/dayweather/d23.png'),
    d24:require('./statics/images/dayweather/d24.png'),
    d25:require('./statics/images/dayweather/d25.png'),
    d26:require('./statics/images/dayweather/d26.png'),
    d27:require('./statics/images/dayweather/d27.png'),
    d28:require('./statics/images/dayweather/d28.png'),
    d29:require('./statics/images/dayweather/d29.png'),
    d30:require('./statics/images/dayweather/d30.png'),
    d31:require('./statics/images/dayweather/d31.png'),
    d32:require('./statics/images/dayweather/d32.png'),
    d33:require('./statics/images/dayweather/d33.png'),
    d49:require('./statics/images/dayweather/d49.png'),
    d53:require('./statics/images/dayweather/d53.png'),
    d54:require('./statics/images/dayweather/d54.png'),
    d55:require('./statics/images/dayweather/d55.png'),
    d56:require('./statics/images/dayweather/d56.png'),
    d57:require('./statics/images/dayweather/d57.png'),
    d58:require('./statics/images/dayweather/d58.png'),
    d99:require('./statics/images/dayweather/d99.png'),
    d301:require('./statics/images/dayweather/d301.png'),
    d302:require('./statics/images/dayweather/d302.png'),
    n00:require('./statics/images/dayweather/n00.png'),
    n01:require('./statics/images/dayweather/n01.png'),
    n02:require('./statics/images/dayweather/n02.png'),
    n03:require('./statics/images/dayweather/n03.png'),
    n04:require('./statics/images/dayweather/n04.png'),
    n05:require('./statics/images/dayweather/n05.png'),
    n06:require('./statics/images/dayweather/n06.png'),
    n07:require('./statics/images/dayweather/n07.png'),
    n08:require('./statics/images/dayweather/n08.png'),
    n09:require('./statics/images/dayweather/n09.png'),
    n10:require('./statics/images/dayweather/n10.png'),
    n11:require('./statics/images/dayweather/n11.png'),
    n12:require('./statics/images/dayweather/n12.png'),
    n13:require('./statics/images/dayweather/n13.png'),
    n14:require('./statics/images/dayweather/n14.png'),
    n15:require('./statics/images/dayweather/n15.png'),
    n16:require('./statics/images/dayweather/n16.png'),
    n17:require('./statics/images/dayweather/n17.png'),
    n18:require('./statics/images/dayweather/n18.png'),
    n19:require('./statics/images/dayweather/n19.png'),
    n20:require('./statics/images/dayweather/n20.png'),
    n21:require('./statics/images/dayweather/n21.png'),
    n22:require('./statics/images/dayweather/n22.png'),
    n23:require('./statics/images/dayweather/n23.png'),
    n24:require('./statics/images/dayweather/n24.png'),
    n25:require('./statics/images/dayweather/n25.png'),
    n26:require('./statics/images/dayweather/n26.png'),
    n27:require('./statics/images/dayweather/n27.png'),
    n28:require('./statics/images/dayweather/n28.png'),
    n29:require('./statics/images/dayweather/n29.png'),
    n30:require('./statics/images/dayweather/n30.png'),
    n31:require('./statics/images/dayweather/n31.png'),
    n32:require('./statics/images/dayweather/n32.png'),
    n33:require('./statics/images/dayweather/n33.png'),
    n49:require('./statics/images/dayweather/n49.png'),
    n53:require('./statics/images/dayweather/n53.png'),
    n54:require('./statics/images/dayweather/n54.png'),
    n55:require('./statics/images/dayweather/n55.png'),
    n56:require('./statics/images/dayweather/n56.png'),
    n57:require('./statics/images/dayweather/n57.png'),
    n58:require('./statics/images/dayweather/n58.png'),
    n99:require('./statics/images/dayweather/n99.png'),
    n301:require('./statics/images/dayweather/n301.png'),
    n302:require('./statics/images/dayweather/n302.png'),

}