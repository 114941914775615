import React,{ Component } from 'react';
import { connect} from 'react-redux';
import { Cascader  } from 'antd';
import {actionCreators} from '../store';
// import {HashRouter,Link} from 'react-router-dom';
import { WeatherH } from '../style';
import img from '../../../dayweather';
import {actionCreators as modifyactionCreators} from '../../../pages/modifyData/components/store';
class NewsZ extends Component{
    constructor(props){
        super(props);
        this.state = {
            changeCity:false
        }
    }
    showChangeCity(){
        this.setState({
            changeCity:true
        })
    }
    onChange(e){
        const city = e[2].split(" ");
        this.setState({
            changeCity:false,
            city:city[1]
        })
        this.props.cityId(city[0],city[1]);
    }
    render(){
        const {city,changeCitys,weather} = this.props;
        return (
            <WeatherH>
                <div className="changeCity">
                    <i className="iconfont icon-location"></i>
                    {this.state.changeCity ?
                    <Cascader className="select" options={city} onChange={(e)=>this.onChange(e)} placeholder="请选择城市"/>
                    : 
                    <div className="qiehuan">
                    {changeCitys}
                    <span onClick={()=>this.showChangeCity()}>[ 切换 ]</span>
                </div>}
                </div>
                <div className="shikuang">
					<a href={"http://www.weatherol.cn/index.html?cityid="+weather.currentData.stationid} target="_blank" rel="noopener noreferrer">
						<img src={img[weather.currentData.current.weatherIndex]} alt=""></img>
						<span>
							<b>{weather.currentData.current.temperature}℃</b>
							<b>{weather.currentData.current.weather}</b>
						</span>
					</a>
                </div>
                <div className="pop"><i className="iconfont"></i>{weather.tips}</div>
                <div className="future">
                    <ul>
                        {weather.preForecastDataList.map((item,index)=>{
                            const date = item.forecasttime.split("-");
                            return (
                            
                                <li key={index+'forcast'}>
                                    <span>
                                        {index === 0 ? 
                                        "今天 " : index === 1 
                                        ? "明天 " : index === 2 
                                        ? "后天 " : null
                                        }{date[1]+'-'+date[2]}
                                    </span>
                                    <span className="weather_tianqi">
                                        {item.weather_am === item.weather_pm ? item.weather_am :item.weather_am+'~'+ item.weather_pm}
                                    </span>
                                    <span>{item.temperature_am}~{item.temperature_pm}℃</span>
                                    <span>
                                        <b style={{
                                            'background':item.aqi === "优" 
                                            ? '#3dc691' : item.aqi === "良" ?
                                            '#f9e535': item.aqi === "轻度污染" ? '#f79b16' : item.aqi === "中度污染" ? '#d34452' :
                                            item.aqi === "重度污染" ? '#9641b9' : item.aqi === "严重污染" ?'#911717':'#666'}}></b>
                                        {item.aqi === "轻度污染" ? "轻度" : item.aqi === "中度污染" ? '中度' : item.aqi === "重度污染" ? '重度' : item.aqi === "严重污染" ? "严重" : item.aqi}
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                    
                </div>
            </WeatherH>
        )
        
    }
    componentDidMount(){
        this.props.getCity();
    }
}
const mapStateToProps = (state)=>{
    return {
        city:state.getIn(['modifyData','city']),
        changeCitys:state.getIn(['header','changeCitys']),
        weather:state.getIn(['header','weather']),
        cityid:state.getIn(['header','cityid'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return {
        getCity(){
            dispatch(modifyactionCreators.getCity())
        },
        // 根据城市id获取天气信息
        cityId(id,city){
            dispatch(actionCreators.cityId(id,city));
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps) (NewsZ)