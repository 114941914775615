import React, { PureComponent } from 'react';
import { BackTop } from 'antd';
import GlobalStyle from './style';
import './statics/iconfont/iconfont.css'
import './statics/iconfonttwo/iconfont.css'
import asyncComponent from './common/components/importComponent'
import Header from './common/header/index'
import store from './store/index'
import { Provider } from 'react-redux'
import { Route,HashRouter,Switch} from 'react-router-dom'
import Http from './http';
const Home = asyncComponent(()=>import("./pages/home"))
const Buy = asyncComponent(()=>import("./pages/buy/loadable"))
const Determine = asyncComponent(()=>import("./pages/determine/loadable"))
const WeatherApi = asyncComponent(()=>import("./pages/weatherApi/loadable"))
const News = asyncComponent(()=>import("./pages/news/loadable"))
const NewsDetail = asyncComponent(()=>import("./pages/newsDetail/loadable"))
const Login = asyncComponent(()=>import("./pages/login/index"))
const Registered = asyncComponent(()=>import("./pages/registered/loadable"))
const Footer = asyncComponent(()=>import("./common/footer/index"))
const PersonalCenter = asyncComponent(()=>import("./pages/personalCenter/loadable"))
const ModifyData = asyncComponent(()=>import("./pages/modifyData/loadable"))
const ResetPassword = asyncComponent(()=>import("./pages/resetPassword/loadable"))
const PayCompleted = asyncComponent(()=>import("./pages/payCompleted/loadable"))
const Developmentdocument = asyncComponent(()=>import("./pages/developmentdocument/loadable"))
const Protocol = asyncComponent(()=>import("./pages/protocol/loadable"))
const Success = asyncComponent(()=>import("./common/success/index"))
const EmailActivation = asyncComponent(()=>import("./pages/emailActivation/loadable"))
const EmailActivationStatus = asyncComponent(()=>import("./pages/emailActivationStatus/loadable"))
const AboutUs = asyncComponent(()=>import("./pages/about/loadable"))
const Invoide = asyncComponent(()=>import("./pages/personalCenter/components/invoide"))
const Four = asyncComponent(()=>import("./pages/404/loadable"))
const five = asyncComponent(()=>import("./pages/500/loadable"))
// import Detail from './pages/detail';
class App extends PureComponent {
  render () {
    Http.init();
    return (
      <Provider store={store}>
        <HashRouter>
          <GlobalStyle />
          <Header />
          <div style={{minHeight:'1000px',marginBottom:"50px"}}>
             <Switch>
                  <Route path='/' exact component={Home} />
                  <Route path='/buy' exact component={Buy} />
                  <Route path='/determine/:id' exact component={Determine} />
                  <Route
                    path='/personalCenter/:enter'
                    exact
                    component={PersonalCenter}
                  />
                  <Route path='/registered' exact component={Registered} />
                  <Route path='/login' exact component={Login} />
                  <Route path='/weatherApi' exact component={WeatherApi} />
                  <Route path='/news' exact component={News} />
                  <Route path='/newsDetail/:id' exact component={NewsDetail} />
                  <Route path='/modifyData' exact component={ModifyData} />
                  <Route path='/resetPassword' exact component={ResetPassword} />
                  <Route path='/payCompleted' exact component={PayCompleted} />
                  <Route path='/developmentdocument/:type' exact component={Developmentdocument}></Route>
                  <Route path='/protocol' exact component={Protocol}></Route>
                  <Route path='/success' exact component={Success}></Route>
                  <Route path='/emailActivation' exact component={EmailActivation}></Route>
                  <Route path='/emailActivationStatus' exact component={EmailActivationStatus}></Route>
                  <Route path='/about' exact component={AboutUs}></Route>
                  <Route path="/invoide/:id" exact component={Invoide}></Route>
                  <Route path='/500' exact component={five}></Route>
                  <Route component={Four} />
                  <BackTop className="backTop">返回顶部</BackTop>
              </Switch>
          </div>
          <Footer />
        </HashRouter>
      </Provider>
    )
  }
}
export default App
// export default App
